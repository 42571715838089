import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    Container,
    TextField,
    Typography,
    Grid,
    Paper,
    IconButton,
    Divider,
    FormControlLabel,
    Switch,
    CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../Pages/PageEditor/MainPage/Header/Header";
import * as tenantsActions from "../../../../store/actions/tenantsActions";
import * as sourceEditorAction from "../../../../store/actions/sourceEditorAction";
import A_CircularIndeternment from "../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import {
    getDbConfigforClient,
    setDbConfigforClient,
} from "./DB_Register_Helper";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import { constVariables } from "../SchedulerEditor/SchedulerEditorHelper";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import Db_Registeration_Form from "./Db_Registeration_Form";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    key: {
        wordBreak: "break-all",
    },
    value: {
        wordBreak: "break-all",
    },
    editSection: {
        marginTop: theme.spacing(2),
    },
    textField: {
        wordBreak: "break-all",
    },
    addIcon: {
        marginLeft: "auto",
    },
    deleteIcon: {
        marginLeft: theme.spacing(1),
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
    },
    centerText: {
        textAlign: "center",
        marginTop: "10px",
    },
}));

const Db_Register = (props) => {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [orgData, setOrgData] = useState({});
    const [editKey, setEditKey] = useState("");
    const [editValue, setEditValue] = useState("");
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const keyInputRef = useRef(null);
    const valueInputRef = useRef(null);
    const [parentKey, setParentKey] = useState("");
    const [isContainer, setIsContainer] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [keyToDelete, setKeyToDelete] = useState("");
    const [loading, setLoading] = useState(false);
    const [globalAddDialogOpen, setGlobalAddDialogOpen] = useState(false);
    const [saveButtonLoading, setSaveButtonLoading] = useState(false);
    const [currentTenant, setCurrentTenant] = useState(
        props.sourceEditor.currentTenant ? props.sourceEditor.currentTenant : ""
    );
    const [toastStatus, setToastStatus] = useState({
        status: false,
        type: "",
        msg: "",
    });

    useEffect(() => {
        if (!currentTenant) {
            props.getTenants();
        }
    }, []);

    useEffect(() => {
        if (currentTenant) {
            getData(currentTenant);
        }
        if (props.sourceEditor && props.sourceEditor.currentTenant) {
            props.getSource(props.sourceEditor.currentTenant);
        }
    }, [currentTenant]);

    const getData = async (id) => {
        setLoading(true);
        let data = await getDbConfigforClient(id);
        let finalData = data || {};
        setLoading(false);
        setData(finalData);
        setOrgData(finalData);
    };
    const tenantChangeHandler = useCallback(
        async (e) => {
            if (!e) {
                setCurrentTenant("");
            } else {
                const currTenant = props.tenants.find(
                    (tenant) => tenant.tenantId === e
                );
                if (currTenant) {
                    setCurrentTenant(currTenant.tenantId);
                    props.getSource(e);
                }
            }
        },
        [props.tenants, props.getSource]
    );

    const handleSnackbarClose = () => {
        setToastStatus({ status: false, msg: "", type: "" });
    };

    const handleEdit = (key, value) => {
        setEditKey(key);
        setEditValue(value);
    };

    const handleSave = () => {
        const keys = editKey.split(".");
        const newData = { ...data };
        let temp = newData;
        keys.forEach((key, index) => {
            if (index === keys.length - 1) {
                temp[key] = editValue;
            } else {
                temp = temp[key];
            }
        });
        setData(newData);
        setEditKey("");
        setEditValue("");
    };

    const handleFinalSave = async () => {
        setSaveButtonLoading(true);
        const newData = { ...data };
        let res = await setDbConfigforClient(currentTenant, newData);
        setToastStatus({
            status: true,
            type: "success",
            msg:
                res && res.data
                    ? res.data
                    : res && res.response && res.response.data
                    ? res.response.data
                    : constVariables.UPDATE,
        });

        setEditKey("");
        setEditValue("");
        setSaveButtonLoading(false);
        if (currentTenant) {
            setTimeout(() => {
                getData(currentTenant);
            }, 1500); //1.5 second
        }
    };

    const handleAdd = () => {
        const newData = { ...data };
        if (parentKey !== "custom") {
            let temp = newData;
            const keys = parentKey.split(".");
            keys.forEach((key, index) => {
                if (index === keys.length - 1) {
                    if (isContainer) {
                        temp[key] = {
                            ...temp[key],
                            [keyInputRef.current.value]: {},
                        };
                    } else {
                        temp[key] = {
                            ...temp[key],
                            [keyInputRef.current.value]:
                                valueInputRef.current.value,
                        };
                    }
                } else {
                    temp = temp[key];
                }
            });
            setData(newData);
        } else {
            let temp = newData;
            temp = { ...temp, [keyInputRef.current.value]: {} };
            setData(temp);
        }
        setAddDialogOpen(false);
        valueInputRef.current.value = "";
        keyInputRef.current.value = "";
        setParentKey("");
        setIsContainer(false);
    };

    const openAddDialog = (key) => {
        setParentKey(key);
        setIsContainer(false);
        setAddDialogOpen(true);
    };

    const openAddDialogContainer = (key) => {
        setParentKey(key);
        setIsContainer(true);
        setAddDialogOpen(true);
    };

    const handleDelete = (keyToDelete) => {
        setKeyToDelete(keyToDelete);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = () => {
        const keys = keyToDelete.split(".");
        const newData = { ...data };
        let temp = newData;
        keys.forEach((key, index) => {
            if (index === keys.length - 1) {
                delete temp[key];
            } else {
                temp = temp[key];
            }
        });
        setData(newData);
        setDeleteDialogOpen(false);
        setKeyToDelete("");
    };

    const cancelDelete = () => {
        setDeleteDialogOpen(false);
        setKeyToDelete("");
    };

    const getLastPart = (str) => {
        const parts = str.split(".");
        return parts[parts.length - 1];
    };
    const handleGlobalAdd = () => {
        setGlobalAddDialogOpen(true);
    };
    const handleGlobalCancel = () => {
        setGlobalAddDialogOpen(false);
    };

    const clientName = (modify = false) => {
        const currTenant =
            props &&
            props.tenants &&
            props.tenants.find((tenant) => tenant.tenantId === currentTenant);
        let label = "";
        if (!modify) {
            label = "this";
        }
        return (currTenant && currTenant.label) || label;
    };
    const InsertConfig = async (json) => {
        setSaveButtonLoading(true);
        const newData = { ...json };
        let res = await setDbConfigforClient(currentTenant, newData);
        setToastStatus({
            status: true,
            type: "success",
            msg:
                res && res.data
                    ? res.data
                    : res && res.response && res.response.data
                    ? res.response.data
                    : constVariables.UPDATE,
        });

        setEditKey("");
        setEditValue("");
        setSaveButtonLoading(false);
        handleGlobalCancel();
        if (currentTenant) {
            setTimeout(() => {
                getData(currentTenant);
            }, 1500); //1.5 second
        }
    };
    const renderData = (data, parentKey = "") => {
        return Object.keys(data).map((key) => {
            const value = data[key];
            const currentKey = parentKey ? `${parentKey}.${key}` : key;
            return (
                <Grid item xs={12} key={currentKey}>
                    {typeof value === "object" ? (
                        <Paper className={classes.paper}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={10}>
                                    <Typography
                                        variant="h6"
                                        className={classes.key}
                                    >
                                        {key}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} container justify="flex-end">
                                    <IconButton
                                        onClick={() =>
                                            openAddDialog(currentKey)
                                        }
                                        color="primary"
                                        className={classes.addIcon}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDelete(currentKey)}
                                        color="secondary"
                                        className={classes.deleteIcon}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: "16px 0" }} />
                            {renderData(value, currentKey)}
                        </Paper>
                    ) : (
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                                <Typography className={classes.key}>
                                    {getLastPart(currentKey)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {editKey === currentKey ? (
                                    <TextField
                                        value={editValue}
                                        onChange={(e) =>
                                            setEditValue(e.target.value)
                                        }
                                        fullWidth
                                        margin="normal"
                                    />
                                ) : (
                                    <Typography className={classes.value}>
                                        {value}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={2} container justify="flex-end">
                                {editKey === currentKey ? (
                                    <A_Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                        label="Save"
                                    />
                                ) : (
                                    <>
                                        <IconButton
                                            onClick={() =>
                                                handleEdit(currentKey, value)
                                            }
                                            color="primary"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                handleDelete(currentKey)
                                            }
                                            color="secondary"
                                            className={classes.deleteIcon}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            );
        });
    };

    return (
        <>
            <Header
                id="toolbar"
                title="DB Registration Editor"
                TenantData={props.tenants}
                value={currentTenant}
                isdisabledFlag={loading}
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />
            {!currentTenant && (
                <Typography variant="h6" className={classes.centerText}>
                    Please choose a Client
                </Typography>
            )}
            {currentTenant && loading && (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            )}
            {currentTenant && !loading && Object.keys(orgData).length === 0 && (
                <div className={classes.loadingContainer}>
                    <Typography variant="h6" className={classes.centerText}>
                        No configuration available:
                    </Typography>
                    &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                    <A_Button
                        color="primary"
                        size="medium"
                        label={`Add Database and Storage Configuration for ${clientName()} Client`}
                        disabled={saveButtonLoading}
                        endIcon={
                            saveButtonLoading ? (
                                <A_CircularIndeternment
                                    color="secondary"
                                    size={20}
                                />
                            ) : (
                                ""
                            )
                        }
                        onClick={handleGlobalAdd}
                    />
                </div>
            )}
            {!loading && currentTenant && Object.keys(orgData).length > 0 && (
                <Container
                    className={classes.container}
                    style={{ maxWidth: "90vw" }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                        }}
                    >
                        <Typography variant="h5" gutterBottom>
                            {`DB Registration available for ${clientName(
                                true
                            )}`}
                        </Typography>
                        <div
                            style={{
                                width: "250px",
                                display: "flex",
                                justifyContent: "space-around",
                            }}
                        >
                            <IconButton
                                onClick={() => openAddDialogContainer("custom")}
                                color="primary"
                                className={classes.addIcon}
                                style={{ marginRight: "5px" }}
                            >
                                <AddIcon />
                            </IconButton>
                            <A_Button
                                color="primary"
                                size="medium"
                                label="Save"
                                disabled={saveButtonLoading}
                                endIcon={
                                    saveButtonLoading ? (
                                        <A_CircularIndeternment
                                            color="secondary"
                                            size={20}
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                onClick={handleFinalSave}
                            />
                        </div>
                    </div>
                    <Grid container spacing={2}>
                        {renderData(data)}
                    </Grid>
                </Container>
            )}
            <A_SimpleDialog
                open={addDialogOpen}
                title="Add New Key-Value or Container"
                fullScreen={false}
                fullWidth={true}
                maxWidth={"sm"}
                onClose={() => setAddDialogOpen(false)}
                dialogContent={
                    <React.Fragment>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isContainer}
                                    onChange={(e) =>
                                        setIsContainer(e.target.checked)
                                    }
                                    color="primary"
                                />
                            }
                            label="Is Container"
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Key"
                            fullWidth
                            inputRef={keyInputRef}
                        />
                        {!isContainer && (
                            <TextField
                                margin="dense"
                                label="Value"
                                fullWidth
                                inputRef={valueInputRef}
                            />
                        )}
                    </React.Fragment>
                }
                dialogActions={
                    <>
                        <A_Button
                            onClick={() => setAddDialogOpen(false)}
                            color="primary"
                            label={"Cancel"}
                        />
                        <A_Button
                            onClick={handleAdd}
                            color="primary"
                            label={"Add"}
                        />
                    </>
                }
            />
            <A_SimpleDialog
                open={deleteDialogOpen}
                onClose={cancelDelete}
                title="Confirm Delete"
                fullScreen={false}
                fullWidth={true}
                maxWidth={"sm"}
                dialogContent={
                    <Typography>
                        Are you sure you want to delete this item?
                    </Typography>
                }
                dialogActions={
                    <>
                        <A_Button onClick={cancelDelete} label={"Cancel"} />

                        <A_Button
                            onClick={confirmDelete}
                            color="secondary"
                            label={"Delete"}
                        />
                    </>
                }
            />
            <A_SimpleDialog
                open={globalAddDialogOpen}
                onClose={handleGlobalCancel}
                title={`Add Database and Storage Configuration for ${clientName()} Client`}
                fullScreen={false}
                fullWidth={true}
                maxWidth={"md"}
                dialogContent={
                    <Db_Registeration_Form InsertConfig={InsertConfig} />
                }
                dialogActions={<></>}
            />
            <A_Snackbar
                type={toastStatus.type}
                open={toastStatus.status}
                message={toastStatus.msg}
                autoHideDuration={3000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
        </>
    );
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        sourceEditor: state.sourceEditor,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, tenantsActions, sourceEditorAction),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Db_Register);
